<template>
  <CardPaymentTotals />

  <div class="form-container">
    <PPCPCard />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useShippingStore } from '@/stores/ShippingStore';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';

import CardPaymentTotals from '@/components/CardPaymentTotals/index.vue';
import PPCPCard from '@/components/Payment/PPCP/Card/index.vue';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';

import { getRouter } from '@/router';

export default {
  name: 'PPCPCheckoutView',
  components: {
    CardPaymentTotals,
    PPCPCard,
  },
  async mounted() {
    if (
      !this.validateAddress('shipping', this.address.shipping) ||
      !this.cart.shipping?.selectedShippingMethod
    ) {
      const router = await getRouter();

      this.goToPreviousStepInCheckout(router);
    }
  },
  computed: {
    ...mapState(useShippingStore, ['address']),
    ...mapState(useAdvertiserStore, ['paymentProvider']),
    ...mapState(useCartStore, ['cart']),
  },
  methods: {
    ...mapActions(useShippingStore, ['validateAddress']),
    ...mapActions(useMainStore, ['goToPreviousStepInCheckout']),
  },
};
</script>
