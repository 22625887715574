<template>
  <div class="payment-popup-splash-screen tw-col-span-12">
    <div>
      <ApplePayMark />

      <div class="lower-text">
        <span class="lower-text__icon">
          <PadlockIconInverted />
        </span>

        <span class="lower-text__text">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ApplePayMark from '@/components/icons/ApplePayMark.vue';
import PadlockIconInverted from '@/components/icons/PadlockIconInverted.vue';

export default defineComponent({
  name: 'PaymentPopupSplashScreen',
  components: { ApplePayMark, PadlockIconInverted },
});
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
