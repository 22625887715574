import { defineStore } from 'pinia';
import { useMainStore } from '@/stores/MainStore';

import { paymentProviderTypes } from '@/types/paymentMethods.types';
import type { PaymentProviderType } from '@/types/paymentMethods.types';

type State = {
  advertiserName: string | null;
  companyName: string | null;
  logo: string | null;
  advertiserTerms: string | null;
  advertiserDelivery: string | null;
  advertiserFooter: string | null;
  advertiserSummaryText: string | null;
  paymentProvider: PaymentProviderType | null;
  primaryColor: string | null;
  secondaryColor: string | null;
  ref?: string;
  redirectUrl?: string;
  termsUrl?: string;
};

const getInitialState = (): State => ({
  advertiserName: null,
  companyName: null,
  logo: null,
  advertiserTerms: null,
  advertiserDelivery: null,
  advertiserFooter: null,
  advertiserSummaryText: null,
  paymentProvider: null,
  primaryColor: null,
  secondaryColor: null,
  redirectUrl: undefined,
  termsUrl: undefined,
});

export const useAdvertiserStore = defineStore('advertiserStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    // TODO replace 'advertiser' with 'retailer' everywhere..
    setData(advertiser) {
      const mainStore = useMainStore();
      const paymentProvider =
        import.meta.env.VITE_PAYMENT_PROVIDER_OVERRIDE && !window.Cypress
          ? import.meta.env.VITE_PAYMENT_PROVIDER_OVERRIDE
          : advertiser.payment_provider;

      console.debug('Initialising retailer', {
        paymentProvider,
      });

      this.advertiserName = advertiser.name;
      this.companyName = advertiser.company_name;
      this.logo = advertiser.logo;
      this.advertiserTerms = advertiser.terms;
      this.termsUrl = advertiser.terms_url;
      this.advertiserDelivery = advertiser.delivery;
      this.advertiserFooter = advertiser.footer_text;
      this.advertiserSummaryText = advertiser.free_delivery_text;
      this.paymentProvider = paymentProvider;
      this.primaryColor = advertiser.checkout_theme?.primary_colour || '#324876';
      this.secondaryColor = advertiser.checkout_theme?.secondary_colour || 'blue';

      // Only supplied in case of 404 on Product lookup
      this.redirectUrl = advertiser.redirect_url;

      // Err if Payment Provider not set as expected
      if (!this.paymentProvider || !paymentProviderTypes.includes(this.paymentProvider)) {
        throw new Error(`Payment provider ${this.paymentProvider} is not set in advertiser data!`);
      }

      mainStore.setPaymentProviderDependentData();
    },
  },
});
