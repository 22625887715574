<template>
  <form @submit.prevent>
    <fieldset v-show="!address.saved[addressType]">
      <h4>Your Details</h4>

      <p class="error--message" v-if="errorMessage">
        <IconError />
        <span>{{ errorMessage }}</span>
      </p>

      <!-- Email -->
      <div class="input-field" v-if="isShipping">
        <input
          type="email"
          v-model="updatedCustomer.email"
          required="required"
          autocomplete="email"
          autofocus
          placeholder=" "
          :id="`${addressType}-input-email`"
          :class="getFieldValidityClass('email', updatedCustomer.email)"
        />

        <label class="label" for="input-email">Email Address</label>

        <IconError />
        <IconValid />
      </div>

      <!-- First name  -->
      <div class="input-field">
        <input
          type="text"
          v-model="updatedCustomer.firstName"
          required="required"
          autocomplete="given-name"
          placeholder=" "
          :id="`${addressType}-input-first-name`"
          :class="getFieldValidityClass('firstName', updatedCustomer.firstName)"
        />

        <label class="label" for="input-name">First Name</label>

        <IconError />
        <IconValid />
      </div>

      <!-- Last name -->
      <div class="input-field">
        <input
          type="text"
          v-model="updatedCustomer.lastName"
          required="required"
          autocomplete="family-name"
          placeholder=" "
          :id="`${addressType}-input-last-name`"
          :class="getFieldValidityClass('lastName', updatedCustomer.lastName)"
        />

        <label class="label" for="input-last-name">Last Name</label>

        <IconError />
        <IconValid />
      </div>

      <!-- Tel -->
      <div class="input-field">
        <input
          type="tel"
          v-model="updatedCustomer.phone"
          required="required"
          autocomplete="tel"
          placeholder=" "
          :id="`${addressType}-input-tel`"
          :class="getFieldValidityClass('phone', updatedCustomer.phone)"
        />

        <label class="label" for="input-tel">Phone Number</label>

        <IconError />
        <IconValid />
      </div>

      <h4>{{ addressType === 'shipping' ? 'Delivery' : 'Billing' }}</h4>

      <div>
        <!-- Postal Code -->
        <div class="input-field">
          <input
            type="text"
            v-model="updatedAddress.postalCode"
            required="required"
            autocomplete="postal-code"
            placeholder=" "
            :id="`${addressType}-input-postal-code`"
            :class="getFieldValidityClass('postalCode', updatedAddress.postalCode)"
          />

          <label class="label" for="input-city">Postcode</label>

          <IconError />
          <IconValid />
        </div>

        <!-- Street Line 1 -->
        <div class="input-field">
          <input
            type="text"
            v-model="updatedAddress.streetAddress[0]"
            required="required"
            autocomplete="address-line1"
            placeholder=" "
            :id="`${addressType}-input-str1`"
            :class="getFieldValidityClass('streetAddress1', updatedAddress.streetAddress[0])"
          />

          <label class="label" for="input-str1">Address Line 1</label>

          <IconError />
          <IconValid />
        </div>

        <!-- Street Line 2 -->
        <div class="input-field">
          <input
            type="text"
            v-model="updatedAddress.streetAddress[1]"
            autocomplete="address-line2"
            placeholder=" "
            :id="`${addressType}-input-str2`"
            :class="getFieldValidityClass('streetAddress2', updatedAddress.streetAddress[1])"
          />

          <label class="label" for="input-str2">Address Line 2</label>

          <IconError />
          <IconValid />
        </div>

        <!-- City -->
        <div class="input-field">
          <input
            type="text"
            v-model="updatedAddress.city"
            required="required"
            autocomplete="address-level2"
            placeholder=" "
            :id="`${addressType}-input-city`"
            :class="getFieldValidityClass('city', updatedAddress.city)"
          />

          <label class="label" for="input-city">Town/City</label>

          <IconError />
          <IconValid />
        </div>

        <!-- Country -->
        <div class="input-field select-input">
          <select
            v-model="updatedAddress.country"
            name="countryList"
            required="required"
            autocomplete="country"
            :id="`${addressType}-countryList`"
            :class="getFieldValidityClass('country', updatedAddress.country)"
          >
            <option
              v-for="country in countryList"
              v-text="country.label"
              :key="country.value"
              :value="country.value"
            />
          </select>

          <IconArrow />

          <label class="label" for="countryList">Country</label>
        </div>
      </div>

      <p class="error--message" v-if="errorMessage">
        <IconError />
        <span>{{ errorMessage }}</span>
      </p>

      <div class="tw-mt-5">
        <button
          type="submit"
          class="button button--full-width button--primary *:tw-inline-block"
          @click="saveAddressForm()"
          data-test="submit-addresses-btn"
          :disabled="isLoading"
          :loading="isLoading"
        >
          Next
        </button>
      </div>
    </fieldset>

    <div v-show="address.saved[addressType]">
      <h4 v-if="addressType === 'shipping'">Shipping Address</h4>

      <section :class="['address-container', addressType === 'billing' ? 'mt-10' : '']">
        <p v-text="customerName + ','"></p>
        <p v-text="streetAddress + ','"></p>
        <p v-text="city"></p>
      </section>

      <button
        type="button"
        v-show="address.saved[addressType]"
        class="button--link"
        @click="editAddress()"
      >
        Edit
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useShippingStore } from '@/stores/ShippingStore';

import IconError from '@/components/icons/IconError.vue';
import IconValid from '@/components/icons/IconValid.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import { isValidEmailAddress, isValidPhoneNumber, isValidPostcode } from '@/helpers/validation';

export default {
  name: 'AddressForm',
  components: {
    IconError,
    IconValid,
    IconArrow,
  },
  data() {
    return {
      isLoading: false,
      validationComplete: false,
      countryList: [
        {
          label: 'United Kingdom',
          value: 'GB',
        },
      ],

      updatedAddress: {
        email: '',
        phone: '',
        streetAddress: [],
        city: '',
        region: '',
        country: '',
        postalCode: '',
      },

      updatedCustomer: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },

      formErrors: [],
    };
  },
  props: {
    addressType: {
      type: String,
    },
  },
  mounted() {
    // The spread here is important! Likely code smell.
    this.updatedAddress = { ...this.address[this.addressType] };
    this.updatedCustomer = { ...this.address.customer[this.addressType] };

    if (this.countryList && this.countryList.length === 1 && this.countryList[0]) {
      this.updatedAddress.country = this.countryList[0].value;
    }
  },
  computed: {
    ...mapState(useShippingStore, ['customer', 'address']),

    customerName() {
      return `${this.address.customer[this.addressType].firstName} ${this.address.customer[this.addressType].lastName}`;
    },

    /**
     * 1st and 2nd line of the address
     */
    streetAddress() {
      let address = this.address[this.addressType].streetAddress[0];

      if (this.address[this.addressType].streetAddress[1]) {
        address = `${address}, ${this.address[this.addressType].streetAddress[1]}`;
      }

      return address;
    },

    /**
     * City, postcode, Country code
     */
    city() {
      return `${this.address[this.addressType].city}, ${this.address[this.addressType].postalCode}, ${this.address[this.addressType].country}`;
    },

    errorMessage() {
      return this.address.errors[this.addressType].message;
    },

    errorFields() {
      return this.address.errors[this.addressType].fields;
    },

    isShipping() {
      return this.addressType === 'shipping';
    },
  },
  methods: {
    ...mapActions(useShippingStore, ['saveAddress', 'setCustomer']),

    async saveAddressForm() {
      this.isLoading = true;
      this.validationComplete = true;

      await this.saveAddress(this.addressType, {
        customer: this.updatedCustomer,
        address: this.updatedAddress,
      });

      this.isLoading = false;
    },

    editAddress() {
      this.address.saved[this.addressType] = false;
      this.address.editing[this.addressType] = true;
    },

    /**
     *  Get CSS class to apply to field based on it validation result.
     */
    getFieldValidityClass(fieldName, value) {
      const validFieldClass = 'input--valid';
      const invalidFieldClass = 'input--invalid';

      if (value || this.validationComplete) {
        // Street lines
        switch (fieldName) {
          //First line of address validation
          case 'streetAddress1':
            return this.errorFields.includes('streetAddress') || !value
              ? invalidFieldClass
              : validFieldClass;

          //Phone validation
          case 'phone':
            return isValidPhoneNumber(this.updatedCustomer.phone)
              ? validFieldClass
              : invalidFieldClass;

          // Email validation
          case 'email':
            return isValidEmailAddress(this.updatedCustomer.email)
              ? validFieldClass
              : invalidFieldClass;

          // Postcode validation
          case 'postalCode':
            return isValidPostcode(this.updatedAddress.postalCode, this.updatedAddress.country)
              ? validFieldClass
              : invalidFieldClass;

          // The rest
          default:
            return this.errorFields.includes(fieldName) && !value
              ? invalidFieldClass
              : validFieldClass;
        }
      }
    },
  },
};
</script>
