import { Sentry } from '@/helpers/moduleMockWrappers/sentry';
import sendEventApi from '@/api/eventTracker/index';
import { useCartStore } from '@/stores/CartStore';

import type { ExpectedEventsType } from '@/types/events.types';
import type { CartJwtPayload } from '@/types/cart.types';

const expectedPaymentTypes = ['paypal', 'googlepay', 'applepay', 'card'];

// @throws
const doValidateEvent = (args) => {
  let isValid = true;

  if (!args?.event) {
    isValid = false;
  }

  switch (args.event) {
    case 'payment_type_selected':
      if (!expectedPaymentTypes.includes(args.data?.paymentType)) {
        isValid = false;
      }
      break;

    default:
      break;
  }

  if (!isValid) {
    throw new Error(`Invalid event args: ${JSON.stringify(args)}`);
  }
};

export const trackEvent = (args: { event: ExpectedEventsType; data?: unknown }) => {
  const cartStore = useCartStore();
  const { cart, cartJwt } = cartStore;

  if (import.meta.env.VITE_APP_ENV === 'local') {
    console.log('Tracking event:', args);
  }

  try {
    // Update payload as per API interface
    const eventPayload: { event_name: ExpectedEventsType; event: undefined; data: unknown } = {
      ...args,
      event_name: args.event,
      event: undefined,
      data: args.data || {},
    };

    // TODO check if error prop is of type 'Error' and then transform to string.

    doValidateEvent(args);

    if (cart) {
      // Cart ID is avilable
      sendEventApi.sendEvent(eventPayload, cart.id, cartJwt as CartJwtPayload);
    } else {
      // TODO call another method for when no Cart ID is supplied (later on)
      console.debug(`Skipping event tracking for event ${args.event} as no Cart ID available.`);
    }
  } catch (err: any) {
    console.error('Failed to track event!', err);
    Sentry.captureException(err, { extra: eventPayload });
  }
};

export default {
  trackEvent,
};
