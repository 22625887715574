<template>
  <div
    v-if="product"
    class="product-view tw-grid tw-grid-cols-12 tw-gap-3 tw-p-3 tw-pt-0"
    :class="{
      'md:tw-gap-7': !this.templateType,
    }"
    data-test="product-view-wrapper"
  >
    <!-- Desktop close icon -->
    <div v-if="!isPDPView" class="tw-col-span-12 tw-flex tw-justify-end max-md:tw-hidden">
      <div class="main-close-icon" data-test="main-close-btn">
        <CloseXIcon />
      </div>
    </div>

    <div class="tw-col-span-12 md:tw-col-span-6">
      <div class="tw-col-span-12 md:tw-hidden tw-flex tw-justify-between tw-items-top">
        <!-- Mobile title -->
        <h2 class="tw-my-3 max-md:tw-text-base" v-text="product.name" />
        <div v-if="!isPDPView" class="main-close-icon tw-pl-5" data-test="main-close-btn">
          <CloseXIcon />
        </div>
      </div>

      <!-- Main hero image and gallery -->
      <div class="product-imagery-container">
        <div class="product-imagery-container__product-gallery">
          <ProductGallery />
        </div>
      </div>
    </div>

    <div class="tw-col-span-12 md:tw-col-span-6 max-md:tw-mt-[-20px]">
      <div class="tw-grid tw-grid-cols-12 tw-gap-3">
        <div class="tw-col-span-12 max-md:tw-hidden">
          <!-- Desktop title -->
          <h2 class="tw-col-span-12 max-md:tw-hidden mb-0 tw-mt-0" v-text="product.name" />
        </div>

        <div v-if="product.shortDescription?.length" class="tw-col-span-12 max-md:tw-hidden">
          {{ product.shortDescription }}
        </div>

        <template v-if="getIsProductInStock">
          <!-- Variant Product Options -->
          <div v-if="isProductHasVariants" class="tw-col-span-6 md:tw-col-span-12">
            <div v-if="getIsShopFrontInitialised">
              <ProductOptions data-test="product-options" />
            </div>
            <div v-else class="skeleton-loader" />
          </div>

          <!-- Product Pricing Info -->
          <div
            :class="{
              'product-headline-and-pricing-container': true,
              'tw-col-span-6 md:tw-col-span-5': isProductHasVariants,
              'tw-col-span-12 md:tw-col-span-5': !isProductHasVariants,
            }"
          >
            <div class="product-info">
              <Product ref="productComponent" />
            </div>
          </div>

          <!-- Discount button, payment buttons-->
          <div id="product-buttons-and-detail-container" class="tw-col-span-12 md:tw-col-span-7">
            <div
              v-if="
                getIsShopFrontInitialised &&
                cart?.pricing &&
                (!isProductHasVariants ||
                  (getIsProductOptionsSelectionComplete && getIsProductOptionsSelectionValid))
              "
            >
              <!-- Discount button -->
              <div v-if="isShowDiscountUiDevice" id="product-promocode-container">
                <Promocode
                  :key="promocodeComponentKey"
                  data-test="promocode-device"
                  :doReloadComponent="resetDiscountDevice"
                />
              </div>

              <!-- Payment Buttons -->
              <div class="payment-buttons-wrapper">
                <div ref="paymentButtonsComponentMarker" />

                <!-- Show a static image of the payment buttons when in Preview Mode -->
                <img v-if="isPreviewMode" :src="previewPaymentButtonsImgSrc" class="pt-20" />

                <Payment v-else :loadStripe="() => {}" />
              </div>
            </div>

            <div v-else class="main-content-loader skeleton-loader tw-col-span-12" />
          </div>
        </template>

        <!-- Product out of stock messaging -->
        <div
          v-else
          class="tw-col-span-12 tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center"
          data-test="out-of-stock-messaging"
        >
          <div class="tw-pb-3 md:tw-pb-0 tw-text-red-600">Out of stock</div>

          <div class="max-md:tw-w-full">
            <button
              v-if="getFallbackUrl"
              type="button"
              class="button button--primary"
              @click="goToOutboundLink(getFallbackUrl)"
              data-test="discover-similar-products-link"
            >
              <span class="tw-text-lg"> Discover similar products </span>
            </button>
          </div>
        </div>
      </div>

      <!-- Product Details -->
      <div class="tw-col-span-12">
        <div v-if="product.shortDescription?.length" class="mt-20 md:tw-hidden">
          {{ product.shortDescription }}
        </div>

        <ProductDetails />
        <ProductFeatures />
        <ProductDelivery />
        <InformationTab />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useProductStore } from '@/stores/ProductStore.ts';
import { usePublisherStore } from '@/stores/PublisherStore.js';
import eventTracker from '@/helpers/eventTracker';
import Product from '@/components/Product/index.vue';
import ProductGallery from '@/components/ProductGallery/index.vue';
import ProductOptions from '@/components/ProductOptions/index.vue';
import ProductFeatures from '@/components/ProductFeatures/index.vue';
import ProductDetails from '@/components/ProductDetails/index.vue';
import ProductDelivery from '@/components/ProductDelivery/index.vue';
import Payment from '@/components/Payment/index.vue';
import Promocode from '@/components/Promocode/index.vue';
import InformationTab from '@/components/InformationTab/index.vue';
import TermsTab from '@/components/Terms/index.vue';
import previewPaymentButtonsImg from '@/assets/static-preview-payment-buttons.jpg';
import CloseXIcon from '@/components/icons/CloseXIcon.vue';

export default {
  components: {
    InformationTab,
    TermsTab,
    Product,
    ProductGallery,
    ProductOptions,
    ProductFeatures,
    ProductDetails,
    ProductDelivery,
    Payment,
    Promocode,
    CloseXIcon,
  },
  data() {
    return {
      promocodeComponentKey: 0,
    };
  },
  computed: {
    ...mapState(useMainStore, [
      'getIsShopFrontInitialised',
      'getIsShowPricingElements',
      'getIsShowHeaderCloseButton',
      'isPreviewMode',
      'isPDPView',
      'templateType',
      'getFallbackUrl',
    ]),
    ...mapState(useCartStore, [
      'cart',
      'getIsProductOptionsSelectionComplete',
      'getIsProductOptionsSelectionValid',
      'isDiscountRevoked',
    ]),
    ...mapState(useAdvertiserStore, ['advertiserName', 'companyName']),
    ...mapState(useProductStore, [
      'product',
      'isProductHasVariants',
      'discountLookupState',
      'getIsProductInStock',
    ]),
    ...mapState(usePublisherStore, ['publisherUrl']),

    ...mapWritableState(useCartStore, ['isDiscountRevoked']),

    isShowDiscountUiDevice() {
      if (this.templateType === 'PILOT_BLACK_FRIDAY_NOV_24') {
        return false;
      }

      if (this.isProductHasVariants) {
        return this.getIsProductOptionsSelectionComplete;
      } else {
        return true;
      }
    },
    previewPaymentButtonsImgSrc() {
      return previewPaymentButtonsImg;
    },
  },
  methods: {
    ...mapActions(useMainStore, ['closeCheckout']),
    ...mapActions(useCartStore, ['getSelectedProductRegularPrice']),
    ...mapActions(useProductStore, ['setDiscountLookupState']),

    resetDiscountDevice() {
      // We are using the 'Key-Changing Technique' to simply a ref-render the Promocode component afresh.
      this.setDiscountLookupState(null);
      this.promocodeComponentKey += 1;
    },
    goToOutboundLink(link) {
      window.open(link, '_blank');
      this.closeCheckout();
    },
  },
  mounted() {
    eventTracker.trackEvent({
      event: 'view_product',
      data: {
        productId: this.product?.id,
        productName: this.product?.name,
        price: this.getSelectedProductRegularPrice,
        advertiserName: this.advertiserName,
        companyName: this.companyName,
        publisherUrl: this.publisherUrl,
      },
    });
  },
  watch: {
    cart(newVal, oldVal) {
      /**
       * Discount revoked is inferred by sensing when a discount has been applied but then removed in a subsequent cart change
       * (e.g. the original discount is no longer applicable based on the new cart contents).
       */
      const discountStatePreviouslySet = !!oldVal && !!newVal && oldVal.isDiscountApplied !== null;
      const isDiscountRevoked =
        discountStatePreviouslySet &&
        newVal.isDiscountApplied === false &&
        oldVal.isDiscountApplied === true;

      if (isDiscountRevoked) {
        this.setDiscountLookupState('DISCOUNT_REVOKED');
      }

      this.isDiscountRevoked = isDiscountRevoked;
    },
    discountLookupState(newVal) {
      if (newVal === 'LOOKUP_ERRED') {
        // this.resetDiscountDevice();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Product.scss';
</style>
