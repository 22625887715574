import { createApp, provide } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import router from '@/router/router.js';
import { LottieAnimation } from 'lottie-web-vue';
import App from './App.vue';
import { useMainStore } from '@/stores/MainStore.ts';
import postMessenger, { initInboundPostMessaging } from '@/plugins/postMessenger';
import eventTracker from '@/helpers/eventTracker';
import globalEventEmitter from '@/plugins/globalEventEmitter';
import mediaMatchInspector from '@/helpers/mediaMatchInspector';
import packageJson from '../package.json';
import { LocalStorageHandler } from '@/helpers/localStorage/index';

const app = createApp(App);

const initSentry = () => {
  if (import.meta.env.VITE_APP_IS_IN_E2E === 'true' || import.meta.env.VITE_APP_ENV === 'local') {
    window.Sentry = {
      captureException: () => {},
    };

    return;
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    // TODO needs thought tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_APP_ENV || 'unspecified',
    release: packageJson.version,
  });
};

// See https://shopthru.sentry.io/projects/checkout-fe/getting-started/?product=session-replay&product=performance-monitoring&siblingOption=vue3
initSentry();

app.use(createPinia());
app.use(router);
app.use(postMessenger);
app.use(mediaMatchInspector);
app.use(globalEventEmitter);
app.provide('appInstance', app);

if (import.meta.env.VITE_FRAMELESS_DEV_MODE === 'true') {
  // Stub out postMessage to avoid JS errors in payment libraries trying to look for a parent iFrame.
  window.postMessage = () => {};
} else {
  initInboundPostMessaging();
}

app.component('lottie-animation', LottieAnimation);

app.config.errorHandler = (error, instance, info) => {
  const mainStore = useMainStore();

  console.error('Error boundary reached with error', error);

  eventTracker.trackEvent({
    event: 'handled_error_thrown',
    data: { error: error },
  });

  try {
    Sentry.captureException(error);
  } catch (err) {
    console.error('Failed to send Sentry log!', err);
  }

  mainStore.clearXhrTracker();

  mainStore.goToErrorPage({
    error,
    isSkipLogging: true,
  });
};

// Set local storage on initial entry
router.beforeEach((to, from, next) => {
  switch (to.name) {
    case 'Root':
      // Entry to default Checkout flow
      LocalStorageHandler.set('checkoutLocation', { ...window.location });
      next({ name: 'Product' });
      break;

    case 'PdpRoot':
      // Entry to PDP flow
      LocalStorageHandler.set('checkoutLocation', { ...window.location });
      next({ name: 'ShopProduct' });
      break;

    default:
      next();
      break;
  }
});

router.isReady().then(() => {
  app.mount('#app');
});

console.log(
  `Shopthru checkout-frame version ${packageJson.version} running in environment: ${import.meta.env.VITE_APP_ENV}`,
);
