<template>
  <div v-if="isRenderComponent">
    <apple-pay-button
      class="apple-pay-button"
      buttonstyle="black"
      type="buy"
      locale="en"
      v-show="!isLoading"
      @click="isPaymentPopup ? startApplePay() : startPaymentPopup('applepay')"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useCartStore } from '@/stores/CartStore.ts';
import { Utils } from '@/helpers/utils';
import constants from '@/constants';
import { ApplePaymentError } from '@/types/errors.types';

export default {
  name: 'PPCPApplePay',
  data() {
    return {
      isLoading: false,
      actions: null,
      shippingContact: null,
      isRenderComponent: true,
      applePayInstance: null,
      applePayConfig: null,
      applePaySession: null,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency', 'isPaymentPopup']),
    ...mapState(usePaymentStore, ['paymentConfig', 'getIsPaymentMethodEnabled']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useAdvertiserStore, ['advertiserName', 'paymentProvider']),
    ...mapState(useCartStore, ['cart', 'getTotalCartPrice', 'getTotalPaymentPrice']),
  },
  async mounted() {
    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'paypal',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'ApplePaySession',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    this.init();
  },
  methods: {
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(usePaymentStore, ['placePPCPOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),
    ...mapActions(useMainStore, [
      'setPaymentButtonInitialised',
      'onLaunchPaymentPopup',
      'goToErrorPage',
    ]),

    onPaymentError(error) {
      const errorFn = () => {
        this.goToErrorPage({
          error,
        });
      };

      if (this.applePaySession?.abort) {
        this.applePaySession.abort();
        errorFn();
      } else {
        errorFn();
      }
    },

    async getApplePayPayConfig() {
      const paypalApi = this.paypalApi || window.paypal;
      const applepay = paypalApi.Applepay();

      const applePayConfig = await applepay.config();

      if (applePayConfig.isEligible && this.getIsPaymentMethodEnabled('applepay')) {
        return applePayConfig;
      }

      console.log('Apple Pay is not supported.');
    },

    getLineItems(shippingMethod) {
      const rtn = [];

      rtn.push({
        type: 'final',
        label: 'Subtotal',
        amount: (this.cart.pricing.totalUnitPrice || 0).toString(),
      });

      rtn.push({
        type: 'final',
        label: 'Discount',
        amount: (this.cart.pricing.totalDiscount || 0).toString(),
      });

      if (shippingMethod) {
        rtn.push({
          type: 'final',
          label: `Shipping (${shippingMethod.label})`,
          amount: (this.cart.pricing.shippingPrice || 0).toString(),
        });
      }

      return rtn;
    },

    async init() {
      try {
        this.applePayInstance = paypal.Applepay();
        this.applePayConfig = await this.getApplePayPayConfig();

        if (!this.isPaymentPopup) {
          if (!this.applePayConfig) {
            this.setPaymentButtonInitialised('applepay', 'NOT_ENABLED');
            this.isRenderComponent = false;
            return;
          }
        }

        this.setPaymentButtonInitialised('applepay', 'INITIALISED');
      } catch (err) {
        throw new ApplePaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    startPaymentPopup(paymentMethod) {
      this.onLaunchPaymentPopup([paymentMethod]);
    },

    async startApplePay() {
      try {
        this.isLoading = true;

        await this.initApplePay();

        this.isLoading = false;
      } catch (err) {
        throw new ApplePaymentError('Error in startApplePay()!', err, this.paymentProvider);
      }
    },

    async initApplePay() {
      console.debug('Initialising Apple Pay instance...');

      try {
        const { countryCode, merchantCapabilities, supportedNetworks } = this.applePayConfig;
        const libVersion = 4;

        const paymentRequestProps = {
          currencyCode: this.currency.currencyCode,
          countryCode: countryCode,
          total: {
            label: this.advertiserName,
            amount: this.getTotalPaymentPrice,
          },
          lineItems: this.getLineItems(),
          requiredBillingContactFields: ['postalAddress'],
          requiredShippingContactFields: ['phone', 'email', 'postalAddress'],
          shippingMethods: [],
          merchantCapabilities: merchantCapabilities,
          supportedNetworks: supportedNetworks,
        };

        this.applePaySession = new window.ApplePaySession(libVersion, paymentRequestProps);

        //
        this.applePaySession.onvalidatemerchant = async (e) => {
          console.debug('Validating merchant...');

          try {
            const validationResult = await this.applePayInstance.validateMerchant({
              validationUrl: e.validationURL,
              displayName: 'Shopthru Checkout',
            });

            this.applePaySession.completeMerchantValidation(validationResult.merchantSession);
          } catch (err) {
            console.error('Failed to validate merchant!', err);

            return this.onPaymentError(
              new ApplePaymentError('Error in onvalidatemerchant()!', err, this.paymentProvider),
            );
          }
        };

        //
        this.applePaySession.onshippingcontactselected = async ({ shippingContact }) => {
          console.debug('Shipping contact was selected...');

          try {
            const applePayShippingContactUpdate = {};

            this.shippingContact = shippingContact;

            const address = {
              streetAddress: [''],
              city: this.shippingContact.locality,
              region: this.shippingContact.administrativeArea,
              postalCode: this.shippingContact.postalCode,
              country: this.shippingContact.countryCode.toUpperCase(),
            };

            if (
              !constants.ALLOWED_SHIPPING_COUNTRY_CODES.includes(this.shippingContact.countryCode)
            ) {
              return this.applePaySession.completeShippingContactSelection({
                newTotal: {
                  label: this.advertiserName,
                  amount: (this.getTotalPaymentPrice || 0).toString(),
                },
                status: window.ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT,
                errors: [
                  new window.ApplePayError(
                    'shippingContactInvalid',
                    'countryCode',
                    'We cannot ship to the selected country.',
                  ),
                ],
              });
            }

            this.setAddress('billing', address);
            this.setAddress('shipping', address);

            await this.fetchShippingMethods();

            const availableShippingMethods = [];

            (this.shippingMethods || []).forEach((option) => {
              availableShippingMethods.push({
                identifier: option.code,
                label: option.label,
                detail: '',
                amount: option.amount,
              });
            });

            applePayShippingContactUpdate.newShippingMethods = availableShippingMethods;

            // Update Shipping code to selected code
            if (!availableShippingMethods.length) {
              throw new Error('No available shipping methods found!');
            }

            await this.setShippingMethod(availableShippingMethods[0].identifier);

            applePayShippingContactUpdate.newTotal = {
              label: this.advertiserName,
              amount: (this.getTotalPaymentPrice || 0).toString(),
            };

            applePayShippingContactUpdate.newLineItems = this.getLineItems();

            this.applePaySession.completeShippingContactSelection(applePayShippingContactUpdate);
          } catch (err) {
            console.error('Failed to update on shipping contact selection!', err);
            throw err;
          }
        };

        //
        this.applePaySession.onshippingmethodselected = async ({ shippingMethod }) => {
          console.debug('Shipping method was selected...');

          try {
            const applePayShippingMethodUpdate = {};

            await this.setShippingMethod(shippingMethod.identifier);

            if (this.shippingContact) {
              // Update Total
              applePayShippingMethodUpdate.newTotal = {
                label: this.advertiserName,
                amount: (this.getTotalPaymentPrice || 0).toString(),
              };

              // Update Totals
              applePayShippingMethodUpdate.newLineItems = this.getLineItems(shippingMethod);

              this.applePaySession.completeShippingMethodSelection(applePayShippingMethodUpdate);
            } else {
              this.applePaySession.completeShippingMethodSelection({});
            }
          } catch (err) {
            console.error('Failed to update on shipping method selection!', err);
            throw err;
          }
        };

        //
        this.applePaySession.onpaymentauthorized = async ({ payment }) => {
          console.debug('Payment was authorised...');

          try {
            await this.applePayInstance.confirmOrder({
              orderId: this.cart.pspOrderId,
              token: payment.token,
              billingContact: payment.billingContact,
            });

            this.paymentAuthorized(payment);
          } catch (err) {
            console.error('Failed to update on payment authorisation!', err);
            throw err;
          }
        };

        //
        this.applePaySession.oncancel = (e) => {
          console.log('Apple Pay Session was cancelled.', e);
        };

        this.applePaySession.begin();
      } catch (err) {
        console.error('Failed to initialise Apple Pay instance!', err);
        throw err;
      }
    },

    /**
     * Update details and place order
     */
    async paymentAuthorized(payment) {
      try {
        const { billingContact, shippingContact } = payment;

        this.handlePaymentAuthorizedPayload({
          billingContact,
          shippingContact,
        });

        this.applePaySession.completePayment(this.applePaySession.STATUS_SUCCESS);

        this.placePPCPOrder('applepay');
      } catch (err) {
        throw new ApplePaymentError('Error in paymentAuthorized()!', err, this.paymentProvider);
      }
    },

    handlePaymentAuthorizedPayload({ shippingContact, billingContact }) {
      try {
        const formattedShippingAddress = {
          streetAddress: shippingContact.addressLines,
          city: shippingContact.locality,
          postalCode: shippingContact.postalCode,
          region: shippingContact.administrativeArea,
          country: shippingContact.countryCode.toUpperCase(),
        };
        const formattedBillingAddress = {
          streetAddress: billingContact.addressLines,
          city: billingContact.locality,
          postalCode: billingContact.postalCode,
          region: billingContact.administrativeArea,
          country: billingContact.countryCode.toUpperCase(),
          email: shippingContact.emailAddress,
        };

        this.setCustomer('shipping', {
          firstName: shippingContact.givenName,
          lastName: shippingContact.familyName,
          phone: shippingContact.phoneNumber,
          email: shippingContact.emailAddress,
        });

        this.setCustomer('billing', {
          firstName: billingContact.givenName,
          lastName: billingContact.familyName,
        });

        this.setAddress('shipping', formattedShippingAddress);
        this.setAddress('billing', formattedBillingAddress);
      } catch (err) {
        throw new ApplePaymentError(
          'Error in handlePaymentAuthorizedPayload()!',
          err,
          this.paymentProvider,
        );
      }
    },
  },
};
</script>

<style lang="scss">
:root {
  --apple-pay-button-height: 50px;
}
</style>
