<template v-if="getIsShopFrontInitialised">
  <div v-if="getIsShopFrontInitialised">
    <CardPaymentTotals>
      <template v-slot:lhs-label>Review your order</template>
    </CardPaymentTotals>

    <div class="review-page-container tw-p-5 tw-pb-0">
      <CartSummaryHeadline />

      <hr class="tw-my-5" />

      <!-- Pricing breakdown -->
      <div data-test="product-container">
        <div class="tw-flex">
          <div class="tw-w-[35%]">Details</div>

          <!-- Subtotal -->
          <div class="tw-w-[65%]">
            <div class="tw-flex tw-justify-between tw-pb-2">
              <span>Subtotal</span>
              <span data-test="sub-total-price">{{
                getFormattedPrice(cart.pricing.totalUnitPrice)
              }}</span>
            </div>

            <!-- Discount -->
            <div
              v-if="cart?.pricing?.totalDiscount"
              class="tw-flex tw-justify-between tw-pb-2"
              data-test="discount-amount-output"
            >
              <span>Discount</span>
              <span>-{{ getFormattedPrice(Math.abs(cart.pricing.totalDiscount)) }}</span>
            </div>

            <!-- Total -->
            <div class="tw-flex tw-justify-between tw-pb-2" data-test="total-amount-output">
              <span>Total</span>
              <span
                ><strong>{{ getFormattedPrice(getTotalPaymentPrice) }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>

      <hr class="tw-mb-5" />

      <div class="tw-text-center">
        <a
          type="button"
          style="text-decoration: underline; cursor: pointer"
          class="tw-text-xs tw-font-light"
          data-test="cancel-and-return-btn"
          @click="closeCheckout"
        >
          Cancel and return to checkout
        </a>

        <Payment class="tw-mt-5" />
      </div>
    </div>
  </div>

  <div v-else>
    <!-- TODO loading indication -->
  </div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import Payment from '@/components/Payment/index.vue';
import ProductGallery from '@/components/ProductGallery/index.vue';
import Footer from '@/components/Footer/index.vue';
import placeholder from '@/assets/placeholder.svg';
import CartSummaryHeadline from '@/components/CartSummaryHeadline/index.vue';
import CardPaymentTotals from '@/components/CardPaymentTotals/index.vue';

export default {
  components: {
    Payment,
    Footer,
    ProductGallery,
    CartSummaryHeadline,
    CardPaymentTotals,
  },
  computed: {
    ...mapState(useMainStore, ['paymentPopupState', 'getIsShopFrontInitialised']),
    ...mapState(useProductStore, ['product']),
    ...mapState(useCartStore, ['cart', 'getFormattedPrice', 'getTotalPaymentPrice']),
    ...mapWritableState(useProductStore, ['isShowStickyApplePaySummary']),
  },
  methods: {
    placeholder() {
      return placeholder;
    },
    closeCheckout() {
      window.close();
    },
  },
};
</script>
