<template>
  <CardPaymentTotals />

  <div class="tw-mt-5">
    <div class="form-container">
      <!-- Cart Summary -->
      <div v-if="isShowShippingMethodsForm">
        <CartSummaryHeadline />
        <hr class="tw-mt-5" />
      </div>

      <ShippingForm />

      <BillingForm v-if="isShowBillingAddressForm" />

      <ShippingMethods
        v-if="isShowShippingMethodsForm"
        :onShippingMethodSelected="onShippingMethodSelected"
      />

      <div class="tw-mt-5">
        <button
          v-if="isShowSubmissionButton"
          type="button"
          class="button button--primary button--full-width"
          :disabled="isSubmissionButtonDisabled || isShippingMethodSelectionInProgress"
          @click="doSubmitShippingStep()"
          data-test="continue-to-payment-btn"
        >
          <transition v-if="isShippingMethodSelectionInProgress" name="fade" mode="out-in">
            <lottie-animation :animationData="loaderAnimation" :loop="true" />
          </transition>
          {{ isShippingMethodSelectionInProgress ? '' : 'Next' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useProductStore } from '@/stores/ProductStore.ts';

import CardPaymentTotals from '@/components/CardPaymentTotals/index.vue';
import ShippingForm from '@/components/Addresses/ShippingForm/index.vue';
import BillingForm from '@/components/Addresses/BillingForm/index.vue';
import ShippingMethods from '@/components/ShippingMethods/index.vue';
import CartSummaryHeadline from '@/components/CartSummaryHeadline/index.vue';

import { getRouter } from '@/router';
import loader from '@/assets/loader.json';
import placeholder from '@/assets/placeholder.svg';

export default {
  name: 'ShippingView',
  components: {
    CartSummaryHeadline,
    CardPaymentTotals,
    ShippingForm,
    BillingForm,
    ShippingMethods,
  },
  computed: {
    ...mapState(useShippingStore, ['address', 'isShippingMethodSelectionInProgress']),
    ...mapState(useCartStore, ['cart', 'getSelectedProductImage']),
    ...mapState(useProductStore, ['product']),

    loaderAnimation() {
      return loader;
    },
    isShowBillingAddressForm() {
      return this.address.saved.shipping;
    },
    isShowShippingMethodsForm() {
      return this.address.saved.shipping && this.address.saved.billing;
    },
    isShowSubmissionButton() {
      return this.isShowShippingMethodsForm;
    },
    isSubmissionButtonDisabled() {
      // Disable the shipping button until the shipping address and shipping method are saved
      return (
        !this.address.saved.shipping ||
        !this.address.saved.billing ||
        !this.cart.shipping?.selectedShippingMethod
      );
    },
  },
  methods: {
    ...mapActions(useShippingStore, ['submitShippingStep']),
    ...mapActions(useMainStore, ['goToPreviousStepInCheckout']),
    ...mapActions(useCartStore, ['setShippingMethod']),

    // Disable the shipping button until the shipping address and shipping method are saved
    disableShippingButton() {
      return (
        !this.address.saved.shipping ||
        !this.address.saved.billing ||
        !this.cart.shipping?.selectedShippingMethod
      );
    },
    async onShippingMethodSelected(shippingMethod) {
      return this.setShippingMethod(shippingMethod);
    },
    async doSubmitShippingStep() {
      const router = await getRouter();
      this.submitShippingStep(router);
    },
    placeholder() {
      return placeholder;
    },
  },
};
</script>
