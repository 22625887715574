import { createWebHistory, createRouter } from 'vue-router';
import Product from '@/views/Product.vue';
import ErrorCatchAll from '@/views/Error.vue';
import Shipping from '@/views/Shipping.vue';
import BraintreeCheckout from '@/views/braintree/Checkout.vue';
import PPCPCheckout from '@/views/ppcp/Checkout.vue';
// import StripeCheckout from '@/views/stripe/Checkout.vue';
// import AdyenCheckout from '@/views/adyen/Checkout.vue';
import PaymentPopup from '@/views/PaymentPopup.vue';
import Thankyou from '@/views/Thankyou.vue';
import ProductNotFound from '@/views/ProductNotFound.vue';
import ShopProduct from '@/views/ShopProduct.vue';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

const routes = [
  {
    path: '/:product',
    name: 'Root',
  },
  {
    path: '/shop/product/:product',
    name: 'PdpRoot',
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/shipping',
    name: 'Shipping',
    component: Shipping,
  },
  {
    path: '/braintree/checkout',
    name: 'BraintreeCheckout',
    component: BraintreeCheckout,
  },
  {
    path: '/ppcp/checkout',
    name: 'PPCPCheckout',
    component: PPCPCheckout,
  },
  /*
  {
    path: '/stripe/checkout',
    name: 'StripeCheckout',
    component: StripeCheckout,
  },
  */
  /*
  {
    path: '/adyen/checkout',
    name: 'AdyenCheckout',
    component: AdyenCheckout,
  },
  */
  {
    path: '/payment-popup',
    name: 'PaymentPopup',
    component: PaymentPopup,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorCatchAll,
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: Thankyou,
  },
  {
    path: '/product-not-found',
    name: 'ProductNotFound',
    component: ProductNotFound,
  },
  {
    path: '/shop/product/product-not-found',
    name: 'ProductNotFoundPDP',
    component: ProductNotFound,
  },
  {
    path: '/shop/product',
    name: 'ShopProduct',
    component: ShopProduct,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.getElementById('app');
    if (element) return elementScrollIntoView(element, { behavior: 'instant' });
  },
});

export default router;
