<template>
  <div
    v-show="snackBarMessage"
    class="snackbar-container"
    :class="{
      'snackbar-container--is-hidden': !isShowMessage,
      'snackbar-container--is-visible': isShowMessage,
    }"
  >
    {{ snackBarMessage }}
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'Snackbar',
  computed: {
    ...mapWritableState(useMainStore, ['snackBarMessage']),
  },
  data() {
    return {
      isShowMessage: false,
    };
  },
  watch: {
    snackBarMessage(newVal) {
      if (newVal) {
        this.isShowMessage = true;

        setTimeout(() => {
          this.isShowMessage = false;
          this.snackBarMessage = null;
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
