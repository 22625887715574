<template>
  <div
    id="payment-buttons-container"
    :class="{
      'mt-20': !templateType,
    }"
    v-if="isRenderComponent"
  >
    <div
      v-show="isPaymentButtonsInitialised"
      class="payment-buttons-container-wrap"
      :class="{
        'is-visible-just-for-unit-test': isPaymentButtonsInitialised,
      }"
      data-test="payment-buttons-is-initialised-wrapper"
    >
      <!-- Adyen fields-->
      <!--<div v-if="paymentProvider === 'adyen'" data-test="payment-buttons-is-adyen-wrapper">-->
      <!--<AdyenFields isShowExpressCheckout />-->

      <!--<CardPaymentSelectionButton :onClick="progressToAdyenCardPaymentFlow" />-->
      <!--</div>-->

      <!-- Stripe fields-->
      <!--<div v-if="paymentProvider === 'stripe'" data-test="payment-buttons-is-stripe-wrapper">-->
      <!-- TODO disable card option here -->
      <!--<StripeFields isShowExpressCheckout :doLoadStripe="loadStripe" />-->

      <!--<CardPaymentSelectionButton :onClick="progressToStripeCardPaymentFlow" />-->
      <!--</div>-->

      <!-- Braintree fields-->
      <div
        v-if="paymentProvider === 'braintree' && paymentConfig?.paymentProviderToken"
        id="payment-buttons-braintree"
        class="text-align-center"
        data-test="payment-buttons-is-braintree-wrapper"
      >
        <BraintreeApplePay
          v-if="isRenderPaymentButton('applepay')"
          v-show="isPaymentButtonInitialisedSuccessfully('applepay')"
          data-test="pay-btn-apple-pay"
        />
        <BraintreeGooglePay
          v-if="isRenderPaymentButton('googlepay')"
          v-show="isPaymentButtonInitialisedSuccessfully('googlepay')"
        />
        <BraintreePaypal
          v-if="isRenderPaymentButton('paypal')"
          v-show="isPaymentButtonInitialisedSuccessfully('paypal')"
        />

        <div v-if="isRenderPaymentButton('card') && isPaymentButtonInitialisedSuccessfully('card')">
          <CardPaymentSelectionButton :onClick="progressToBraintreeCardPaymentFlow" />
        </div>
      </div>

      <!-- PPCP fields-->
      <div
        v-if="paymentProvider === 'ppcp'"
        id="payment-buttons-ppcp"
        class="text-align-center"
        data-test="payment-buttons-is-ppcp-wrapper"
      >
        <PPCPApplePay
          v-if="isRenderPaymentButton('applepay')"
          v-show="isPaymentButtonInitialisedSuccessfully('applepay')"
          data-test="pay-btn-apple-pay"
        />

        <PPCPGooglePay
          v-if="isRenderPaymentButton('googlepay')"
          v-show="isPaymentButtonInitialisedSuccessfully('googlepay')"
          data-test="pay-btn-google-pay"
        />

        <PPCPPayPal
          v-if="isRenderPaymentButton('paypal')"
          v-show="isPaymentButtonInitialisedSuccessfully('paypal')"
          data-test="pay-btn-paypal"
        />

        <div v-if="isRenderPaymentButton('card') && isPaymentButtonInitialisedSuccessfully('card')">
          <CardPaymentSelectionButton :onClick="progressToPPCPCardPaymentFlow" />
        </div>
      </div>
    </div>

    <div v-show="!isPaymentButtonsInitialised" class="loading-message-container skeleton-loader" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';

import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';

import { Utils } from '@/helpers/utils';

import CardPaymentSelectionButton from '@/components/Payment/CardPaymentSelectionButton.vue';
import BraintreeGooglePay from '@/components/Payment/Braintree/BraintreeGooglePay/index.vue';
import BraintreeApplePay from '@/components/Payment/Braintree/BraintreeApplePay/index.vue';
import BraintreePaypal from '@/components/Payment/Braintree/BraintreePaypal/index.vue';
import PPCPApplePay from '@/components/Payment/PPCP/ApplePay/index.vue';
import PPCPGooglePay from '@/components/Payment/PPCP/GooglePay/index.vue';
import PPCPPayPal from '@/components/Payment/PPCP/PayPal/index.vue';
import PPCPCard from '@/components/Payment/PPCP/Card/index.vue';

// //// import AdyenFields from '@/components/Payment/Adyen/AdyenFields.vue';
// //// import StripeFields from '@/components/Payment/Stripe/StripeFields.vue';

import { getRouter } from '@/router';
import eventTracker from '@/helpers/eventTracker.ts';
import constants from '@/constants';

export default {
  name: 'PaymentComponent',
  components: {
    // AdyenFields,
    // StripeFields,
    CardPaymentSelectionButton,
    BraintreeGooglePay,
    BraintreeApplePay,
    BraintreePaypal,
    PPCPApplePay,
    PPCPGooglePay,
    PPCPPayPal,
    PPCPCard,
  },
  props: {
    loadStripe: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isRenderComponent: false,
      open: false,
      paymentButtonInitialisationTimerId: null,
      paymentButtonInitialisationTimerExpired: false,
    };
  },
  async mounted() {
    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'paypal',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    this.startPaymentButtonInitialisationTimer();

    this.isRenderComponent = true;
  },
  beforeUnmount() {
    if (this.paymentButtonInitialisationTimerId) {
      clearTimeout(this.paymentButtonInitialisationTimerId);
    }
  },
  computed: {
    ...mapState(useAdvertiserStore, ['companyName', 'advertiserTerms', 'paymentProvider']),
    ...mapState(usePaymentStore, ['paymentConfig']),
    ...mapState(useMainStore, [
      'getIsApplePaySupported',
      'isPaymentButtonsInitialised',
      'initialisedPaymentButtons',
      'isPaymentPopup',
      'paymentPopupState',
      'templateType',
    ]),
  },
  methods: {
    ...mapActions(useMainStore, ['assessAvailablePaymentButtonsAfterInitialisationAttempted']),

    async progressToBraintreeCardPaymentFlow() {
      const router = await getRouter();

      eventTracker.trackEvent({
        event: 'payment_type_selected',
        data: { paymentType: 'card' },
      });

      router.push({ name: 'Shipping' });
    },

    async progressToStripeCardPaymentFlow() {
      const router = await getRouter();

      eventTracker.trackEvent({
        event: 'payment_type_selected',
        data: { paymentType: 'stripe_card_payment' },
      });

      router.push({ name: 'StripeCheckout' });
    },

    async progressToAdyenCardPaymentFlow() {
      const router = await getRouter();

      eventTracker.trackEvent({
        event: 'payment_type_selected',
        data: { paymentType: 'adyen_card_payment' },
      });

      router.push({ name: 'Shipping' });
    },

    async progressToPPCPCardPaymentFlow() {
      const router = await getRouter();
      router.push({ name: 'Shipping' });
    },

    /**
     * Wait a finite amount of time before deciding to conditionally render payment buttons based on whether they successfully initialised in
     * an arbritray but reasonable amount of time. I.e. upon this timeout, then only render buttons which initialised, or rather don't let one failed
     * button prevent any of them from showing.
     */
    startPaymentButtonInitialisationTimer() {
      this.paymentButtonInitialisationTimerId = setTimeout(() => {
        this.assessAvailablePaymentButtonsAfterInitialisationAttempted();
        this.paymentButtonInitialisationTimerExpired = true;
      }, constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT);
    },

    isPaymentButtonInitialisedSuccessfully(flag) {
      return this.initialisedPaymentButtons[flag] === 'INITIALISED';
    },

    isRenderPaymentButton(paymentMethod) {
      let rtn = true;

      switch (paymentMethod) {
        case 'applepay':
          if (!this.isPaymentPopup) {
            rtn = this.getIsApplePaySupported;
          }
          break;

        case 'googlepay':
          if (
            this.isRenderPaymentButton('applepay') &&
            this.isPaymentButtonInitialisedSuccessfully('applepay')
          ) {
            // Only render Google Pay on Apple Pay devices if Apple Pay failed to initialise.
            rtn = false;
          }
          break;

        case 'card':
          if (this.paymentProvider === 'ppcp') {
            // Check card payment is eligible
            const cardField = window.paypal?.CardFields({});
            rtn = cardField?.isEligible();
          }

          // TODO perhaps the other PSPs also need to have this check here?
          break;

        default:
          break;
      }

      return rtn && this.isShowPaymentMethodIfInPopup(paymentMethod);
    },

    isShowPaymentMethodIfInPopup(paymentMethod) {
      if (this.isPaymentPopup) {
        return this.paymentPopupState.paymentConfig.paymentMethods.includes(paymentMethod);
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import './payment-buttons.scss';
</style>

<style lang="scss" scoped>
@import './styles.scss';
</style>
