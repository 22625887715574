<template>
  <div class="notification notification--success">
    <div class="notification__icon mb-10">
      <lottie-animation :animationData="successAnimation" :auto-play="true" />
    </div>

    <div class="notification-text">
      <div v-if="isPaymentPopup">Payment complete, redirecting...</div>

      <div v-else>
        <h2 class="colour-primary">{{ companyName }} have received your order!</h2>

        <p>Order Number {{ cart.transactionId }}</p>

        <p class="font-weight-light mt-20" data-test="email-confirmation-message">
          A copy of your receipt has been emailed to
          <span class="font-weight-normal" v-if="cart?.confirmationEmailAddress">
            <br />
            {{ cart?.confirmationEmailAddress }}
          </span>

          <span v-else>you</span>
        </p>

        <div class="tw-mt-20">
          <button
            type="button"
            class="button button--primary button--full-width"
            @click="closeCheckout()"
            data-test="done-btn"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import successAnimation from '@/assets/lottie/success.json';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';

export default {
  name: 'Thankyou',
  computed: {
    ...mapState(useMainStore, ['isPaymentPopup']),
    ...mapState(useAdvertiserStore, ['companyName']),
    ...mapState(useCartStore, ['cart', 'cartJwt']),

    successAnimation() {
      return successAnimation;
    },
  },
  methods: {
    ...mapActions(useMainStore, ['closeCheckout']),
  },
  mounted() {
    if (this.isPaymentPopup) {
      this.$postMessengerOpener({
        type: 'CHECKOUT_PAYMENT_POPUP',
        key: 'PAYMENT_COMPLETE_RESPONSE',
        message: {
          cartJwt: this.cartJwt,
        },
      });

      // Close the payment popup window and return to Checkout parent page
      setTimeout(window.close, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
@import './Notification.scss';
</style>
