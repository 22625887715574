<template>
  <div
    v-if="product"
    class="product-view pdp-view tw-grid tw-grid-cols-12 tw-gap-x-4 md:tw-gap-x-7"
    data-test="product-view-wrapper"
  >
    <!-- Main hero image and gallery -->
    <!-- Make sure to set the row span bigger than the number of rows on the other side to avoid content jumping -->
    <div class="product-imagery-container md:tw-row-span-6 md:tw-col-span-4 tw-col-span-12">
      <div class="product-imagery-container__product-gallery pdp-layout">
        <ProductGallery />
      </div>
    </div>

    <!-- Product Name -->
    <div
      class="md:tw-col-start-5 md:tw-col-span-8 md:tw-row-start-1 tw-col-span-12 tw-row-start-2 product-name"
    >
      <h1 class="mt-0" v-text="product.name"></h1>
    </div>

    <!-- Product Short Description -->
    <div
      class="md:tw-col-span-8 md:tw-col-start-5 tw-col-span-12 md:tw-row-start-2 short-description"
    >
      <p class="mt-0" v-text="product.shortDescription"></p>
    </div>

    <template v-if="getIsProductInStock">
      <div
        v-if="getIsShopFrontInitialised && isProductHasVariants"
        class="options-container lg:tw-col-span-4 md:tw-col-start-5 lg:tw-col-start-5 tw-col-span-12 tw-row-start-3"
        :class="product.shortDescription?.length ? 'lg:tw-row-start-3' : 'lg:tw-row-start-2'"
      >
        <ProductOptions data-test="product-options" />
      </div>

      <!-- Free Shipping Text -->
      <div
        v-if="advertiserSummaryText"
        class="md:tw-col-span-4 md:tw-col-start-5 tw-col-span-12 free-text"
      >
        <p v-text="advertiserSummaryText"></p>
      </div>
    </template>

    <!-- Product out of stock messaging -->
    <div
      v-else
      class="tw-col-span-12 tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center"
      data-test="out-of-stock-messaging"
    >
      <div class="tw-pb-3 md:tw-pb-0 tw-text-red-600">Out of stock</div>

      <div class="max-md:tw-w-full">
        <button
          v-if="getFallbackUrl"
          type="button"
          class="button button--primary"
          @click="goToOutboundLink(getFallbackUrl)"
          data-test="discover-similar-products-link"
        >
          <span class="tw-text-lg"> Discover similar products </span>
        </button>
      </div>
    </div>

    <div class="tw-col-span-12 lg:tw-col-span-4 md:tw-col-start-5 tw-order-last tabs-container">
      <ProductDetails />
      <ProductFeatures />
      <ProductDelivery />
      <InformationTab />

      <!--      Commented until future implementation -->
      <!--      <TermsTab />-->
    </div>

    <!-- Discount button, payment buttons and product details -->
    <div
      v-if="getIsProductInStock"
      id="product-buttons-and-detail-container"
      class="tw-col-span-12 lg:tw-col-span-4 lg:tw-col-start-9 md:tw-col-start-5 md:tw-row-span-3"
      :class="[
        product.shortDescription?.length ? 'lg:tw-row-start-3' : 'lg:tw-row-start-2',
        !getIsShopFrontInitialised || !cart?.pricing ? 'skeleton-loader' : '',
      ]"
    >
      <div
        v-if="
          getIsShopFrontInitialised &&
          cart?.pricing &&
          (!isProductHasVariants ||
            (getIsProductOptionsSelectionComplete && getIsProductOptionsSelectionValid))
        "
      >
        <span
          v-if="$isScreenSizeLessThan('m')"
          @click="openProductDescription()"
          translate="no"
          class="notranslate product-details-link"
        >
          Product Details
          <IconArrow />
        </span>

        <div class="payment-buttons-wrapper">
          <PriceAndQty />
          <div v-if="isShowDiscountUiDevice" id="product-promocode-container">
            <Promocode
              :key="promocodeComponentKey"
              data-test="promocode-device"
              :doReloadComponent="resetDiscountDevice"
            />

            <div class="horizontal-line"></div>
          </div>

          <!-- Show a static image of the payment buttons when in Preview Mode -->
          <img
            v-if="isPreviewMode"
            alt="payment-preview"
            :src="previewPaymentButtonsImgSrc"
            class="pt-20"
          />

          <Payment v-else :loadStripe="() => {}" />

          <span class="payment-note"
            >Sold and Dispatched from <strong>{{ companyName }}</strong></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useProductStore } from '@/stores/ProductStore.ts';
import { usePublisherStore } from '@/stores/PublisherStore.js';
import ProductGallery from '@/components/ProductGallery/index.vue';
import ProductFeatures from '@/components/ProductFeatures/index.vue';
import ProductDetails from '@/components/ProductDetails/index.vue';
import ProductDelivery from '@/components/ProductDelivery/index.vue';
import ProductOptions from '@/components/ProductOptions/index.vue';
import Payment from '@/components/Payment/index.vue';
import Promocode from '@/components/PromocodeLocketDesign/index.vue';
import InformationTab from '@/components/InformationTab/index.vue';
import PriceAndQty from '@/components/PriceAndQty/index.vue';
import TermsTab from '@/components/Terms/index.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import previewPaymentButtonsImg from '@/assets/static-preview-payment-buttons.jpg';
import { polyfill, scrollTo } from 'seamless-scroll-polyfill';

export default {
  components: {
    InformationTab,
    TermsTab,
    ProductGallery,
    ProductFeatures,
    ProductDetails,
    ProductDelivery,
    Payment,
    Promocode,
    ProductOptions,
    PriceAndQty,
    IconArrow,
  },
  data() {
    return {
      promocodeComponentKey: 0,
    };
  },
  computed: {
    ...mapState(useMainStore, [
      'getIsShopFrontInitialised',
      'getIsShowPricingElements',
      'isPreviewMode',
      'getFallbackUrl',
    ]),
    ...mapState(useCartStore, [
      'cart',
      'getIsProductOptionsSelectionComplete',
      'getIsProductOptionsSelectionValid',
      'isDiscountRevoked',
    ]),
    ...mapState(useAdvertiserStore, ['advertiserName', 'companyName', 'advertiserSummaryText']),
    ...mapState(useProductStore, [
      'product',
      'isProductHasVariants',
      'discountLookupState',
      'getIsProductInStock',
    ]),
    ...mapState(usePublisherStore, ['publisherUrl']),

    ...mapWritableState(useCartStore, ['isDiscountRevoked']),

    isShowDiscountUiDevice() {
      if (this.isProductHasVariants) {
        return this.getIsProductOptionsSelectionComplete;
      } else {
        return true;
      }
    },
    previewPaymentButtonsImgSrc() {
      return previewPaymentButtonsImg;
    },
  },
  methods: {
    ...mapActions(useMainStore, ['closeCheckout']),
    ...mapActions(useCartStore, ['getSelectedProductRegularPrice']),
    ...mapActions(useProductStore, ['setDiscountLookupState']),

    resetDiscountDevice() {
      // We are using the 'Key-Changing Technique' to simply a ref-render the Promocode component afresh.
      this.setDiscountLookupState(null);
      this.promocodeComponentKey += 1;
    },

    openProductDescription() {
      polyfill();

      const targetElement = document.getElementById('product-details-tab');
      const arbitraryTopOffset = 0; // Estimation of sticky header height

      if (targetElement) {
        const targetElementYpos = targetElement.getBoundingClientRect().top;

        this.currentlyOpenInfoTab = 'PRODUCT_DETAILS';

        scrollTo(window.document.body, {
          behavior: 'smooth',
          left: 0,
          top: targetElementYpos - arbitraryTopOffset,
        });
      }
    },

    goToOutboundLink(link) {
      window.open(link, '_blank');
      this.closeCheckout();
    },
  },
  watch: {
    cart(newVal, oldVal) {
      /**
       * Discount revoked is inferred by sensing when a discount has been applied but then removed in a subsequent cart change
       * (e.g. the original discount is no longer applicable based on the new cart contents).
       */
      const discountStatePreviouslySet = !!oldVal && !!newVal && oldVal.isDiscountApplied !== null;
      const isDiscountRevoked =
        discountStatePreviouslySet &&
        newVal.isDiscountApplied === false &&
        oldVal.isDiscountApplied === true;

      if (isDiscountRevoked) {
        this.setDiscountLookupState('DISCOUNT_REVOKED');
      }

      this.isDiscountRevoked = isDiscountRevoked;
    },
    discountLookupState(newVal) {
      if (newVal === 'LOOKUP_ERRED') {
        // this.resetDiscountDevice();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Product.scss';
</style>
