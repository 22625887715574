<template>
  <div class="product-issue-wrapper tw-flex tw-justify-center">
    <div class="product-issue-content tw-px-5">
      <slot></slot>

      <div class="tw-mt-20 tw-px-10">
        <button
          v-if="outboundLinkHref"
          type="button"
          class="button button--full-width button--primary"
          @click="goToOutboundLink()"
          data-test="outbound-link-btn"
        >
          Discover similar products
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'ProductIssue',
  props: {
    outboundLinkHref: {
      type: String,
    },
  },
  methods: {
    ...mapActions(useMainStore, ['closeCheckout']),

    goToOutboundLink() {
      window.open(this.outboundLinkHref, '_blank');

      this.closeCheckout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
