<template>
  <button
    aria-label="Credit or Debit Card"
    class="button button--primary tw-rounded-full button--full-width card-payment"
    @click="onClick"
    type="button"
    data-test="hosted-fields-payment-btn"
  >
    <IconCard />
  </button>
</template>

<script>
import IconCard from '@/components/icons/IconCardForButton.vue';

export default {
  name: 'CardPaymentSelectionButton',
  components: { IconCard },
  props: {
    onClick: Function,
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
