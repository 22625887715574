<template>
  <div class="tw-flex tw-justify-between tw-items-center tw-px-5 tw-pt-5 tw-pb-4">
    <slot name="lhs-label" />

    <div
      v-if="!$slots['lhs-label']"
      @click.prevent="doPreviousStepInCheckout()"
      class="tw-inline tw-cursor-pointer"
    >
      < Back
    </div>

    <div class="tw-flex tw-items-start">
      <span class="tw-text-xs" v-text="totalPriceSplit.currency" />
      <span v-text="totalPriceSplit.whole" class="tw-text-xl tw-leading-[1.1]" />
      <span class="tw-text-xs" v-text="totalPriceSplit.remainder" />
    </div>
  </div>

  <hr class="tw-m-0 tw-mx-5" />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useCartStore } from '@/stores/CartStore';
import { useMainStore } from '@/stores/MainStore';
import { getRouter } from '@/router';
import TextFormat from '@/helpers/textFormat';

export default {
  name: 'CardPaymentTotals',
  computed: {
    ...mapState(useCartStore, ['getFormattedPrice', 'getTotalPaymentPrice']),

    totalPriceSplit() {
      return TextFormat.splitFormattedPrice(this.getFormattedPrice(this.getTotalPaymentPrice));
    },
  },
  methods: {
    ...mapActions(useMainStore, ['goToPreviousStepInCheckout']),

    async doPreviousStepInCheckout() {
      const router = await getRouter();
      this.goToPreviousStepInCheckout(router);
    },
  },
};
</script>
