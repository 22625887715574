<template>
  <div class="tw-flex">
    <img
      class="tw-h-[100px] tw-mr-2"
      :src="getSelectedProductImage"
      :onerror="`this.onerror=null; this.src='${placeholder()}';`"
    />

    <div data-test="product-name-and-quantity" class="tw-pl-2">
      <strong>{{ cart.productQuantity }} x</strong> {{ product.name }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';

import placeholder from '@/assets/placeholder.svg';

export default {
  name: 'CartSummaryHeadline',
  computed: {
    ...mapState(useProductStore, ['product']),
    ...mapState(useCartStore, ['cart', 'getSelectedProductImage']),
  },
  methods: {
    placeholder() {
      return placeholder;
    },
  },
};
</script>
