<template>
  <IconClose
    v-if="getIsShowHeaderCloseButton($route)"
    class="icon-close"
    data-test="close-button"
    @click="closeCheckout()"
  />
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import IconClose from '@/components/icons/IconClose.vue';

export default {
  components: {
    IconClose,
  },
  computed: {
    ...mapState(useMainStore, ['getIsShowHeaderCloseButton']),
  },
  methods: {
    ...mapActions(useMainStore, ['closeCheckout']),
  },
};
</script>

<style lang="scss" scoped>
@import './CloseXIcon.scss';
</style>
